import { Box, Button, Flex, Input, Spinner } from '@chakra-ui/react';
import { CountrySelector, defaultCountries, parseCountry, usePhoneInput } from 'react-international-phone';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { getValidPhoneByPartner, isUK } from '../utils/partnerTheme';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import useValidatePhone from '../hooks/validate-phone-on-debounce';
interface ChakraPhoneProps {
  value: string;
  onChange: (phone: string) => void;
  disableBtn?: (e: boolean) => void;
}
const PhoneInput: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
  disableBtn
}) => {
  const {
    debounceOnChange,
    isPhoneInputFocus,
    setIsPhoneInputFocus,
    isPhoneValid,
    setIsPhoneValid,
    isCheckingPhoneValid
  } = useValidatePhone();
  const [phone, setPhone] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);
  const countries = defaultCountries.filter(country => {
    const {
      iso2
    } = parseCountry(country);
    return [...(isUK ? ['gb'] : ['ng'])].includes(iso2);
  });
  const phoneInput = usePhoneInput({
    defaultCountry: isUK ? 'gb' : 'ng',
    value: phone,
    onChange: data => {
      setPhone(data.phone);
      onChange(data.phone);
    },
    countries
  });
  const formatPhoneNumber = (input: string) => {
    try {
      let phoneNumber = input.trim();
      const countryCode = phoneInput.country.dialCode;

      // Handle different formats
      if (!phoneNumber.startsWith('+')) {
        // Remove leading 0 if present
        if (phoneNumber.startsWith('0')) {
          phoneNumber = phoneNumber.substring(1);
        }

        // Add country code if not present
        if (!phoneNumber.startsWith(countryCode)) {
          phoneNumber = `+${countryCode}${phoneNumber}`;
        } else {
          phoneNumber = `+${phoneNumber}`;
        }
      }

      // Validate using libphonenumber-js
      if (isValidPhoneNumber(phoneNumber)) {
        const parsedNumber = parsePhoneNumber(phoneNumber);
        return parsedNumber.format('E.164');
      }
      return phoneNumber;
    } catch (error) {
      return input;
    }
  };
  const handlePhoneValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line prefer-const
    let inputValue = e.target.value;
    const countryCode = phoneInput.country.dialCode;

    // Prevent multiple plus signs or country codes
    if (inputValue.split('+').length > 2) {
      return; // Don't allow more than one plus sign
    }

    // If user tries to add country code in the middle of the number
    if (inputValue.includes(`+${countryCode}`, 1)) {
      return; // Don't allow country code in the middle
    }
    const formattedNumber = formatPhoneNumber(inputValue);
    setPhone(formattedNumber);
    onChange(formattedNumber);
    if (formattedNumber !== previousValue) {
      debounceOnChange(formattedNumber, disableBtn);
      setIsPhoneValid('typing');
    }
  };
  useEffect(() => {
    if (value && value !== previousValue) {
      const formattedNumber = formatPhoneNumber(value);
      setPhone(formattedNumber);
      onChange(formattedNumber);
      setPreviousValue(formattedNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    if (isPhoneValid !== true) {
      disableBtn?.(true);
    } else {
      disableBtn?.(false);
    }
  }, [disableBtn, isPhoneValid]);
  useEffect(() => {
    if (value) {
      debounceOnChange(value, disableBtn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
      <Flex align="center" pos="relative" data-sentry-element="Flex" data-sentry-source-file="PhoneInput.tsx">
        <CountrySelector selectedCountry={phoneInput.country.iso2} onSelect={country => phoneInput.setCountry(country.iso2)} renderButtonWrapper={({
        children,
        rootProps
      }) => <Button {...rootProps} variant="outline" p={6} mr="8px">
              {children}
            </Button>} countries={countries} data-sentry-element="CountrySelector" data-sentry-source-file="PhoneInput.tsx" />
        <Input pos="relative" placeholder="Enter Phone number" type="tel" value={phone} p={6} ref={phoneInput.inputRef} onChange={handlePhoneValueChange} onBlur={() => setIsPhoneInputFocus(false)} onFocus={() => setIsPhoneInputFocus(true)} data-sentry-element="Input" data-sentry-source-file="PhoneInput.tsx" />
        <Flex align="center" pos="absolute" right="5" height="full" zIndex={1} data-sentry-element="Flex" data-sentry-source-file="PhoneInput.tsx">
          {isPhoneInputFocus && (isPhoneValid === 'typing' || isCheckingPhoneValid ? <Spinner size="sm" /> : isPhoneValid ? <MdCheckCircle /> : <MdCancel />)}
        </Flex>
      </Flex>
      {!isPhoneValid && <Box display="flex" alignItems="center" gap={2} px={2} py={1} bg="red.100">
          {String(isPhoneValid) === 'typing' || isCheckingPhoneValid ? <Spinner size="sm" /> : isPhoneValid ? <MdCheckCircle /> : <MdCancel />}{' '}
          {getValidPhoneByPartner()}
        </Box>}
    </>;
};
export default PhoneInput;