/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Flex, FlexProps, Box } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';
import { getLogoByPartner, getTitleByPartner } from '../utils/partnerTheme';
function Logo(props: FlexProps) {
  return (
    //@ts-ignore
    <Flex {...props} w="100%" justifyContent="center" data-sentry-element="Flex" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      {/* w={{ base: '95px', sm: '100%' }} */}
      <Box data-sentry-element="Box" data-sentry-source-file="Logo.tsx">
        <Image width={200} src={getLogoByPartner()} alt={`${getTitleByPartner()} Logo`} data-sentry-element="Image" data-sentry-source-file="Logo.tsx" />
      </Box>
    </Flex>
  );
}
export default Logo;