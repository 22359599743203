import React, { useState } from 'react';
import { Flex, Text, Button, Alert, AlertIcon, AlertDescription, Spacer, CloseButton, Box, Divider, AbsoluteCenter, Input, useToast } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { VERIFY_PHONE } from '../../../api/mutations/auth';
import { useRouter } from 'next/router';
import PhoneInput from '../../../components/PhoneInput';
import Link from 'next/link';
import { validateEmail } from '../../../utils/util';
const LoginForm = () => {
  const [userDetail, setUserDetail] = useState('');
  const toast = useToast();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isSubmitBtnDisabled, setDisableSubmitBtn] = useState(true);
  const [showForm, setShowForm] = useState(0);
  const router = useRouter();
  const [verifyPhone, {
    loading: verifyingPhone,
    error: verifyPhoneError
  }] = useMutation(VERIFY_PHONE, {
    onCompleted: data => {
      const {
        verifyPhone: {
          pinId,
          to
        }
      } = data;
      router.push(`/otp/${pinId}?to=${to}&login=${true}&type=${showForm === 1 ? 'phone' : 'email'}${router.query.returnUrl ? `&returnUrl=${router.query.returnUrl}` : ''}`);
    },
    onError: error => {
      console.log(error);
      setShowErrorAlert(true);
    }
  });
  return <Flex flexDirection="column" gap={4} data-sentry-element="Flex" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <Text fontSize="20px" fontWeight="300" data-sentry-element="Text" data-sentry-source-file="LoginForm.tsx">
        Welcome Back
      </Text>
      {!showForm ? <>
          <Button bg="white" p={6} border="1px solid #D0D5DD" color="gray" _hover={{
        bg: '#0F61D6',
        opacity: '0.9',
        color: 'white'
      }} colorScheme="blue" onClick={() => setShowForm(1)}>
            Log in with Phone Number
          </Button>
          <Box position="relative" padding="5">
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              OR
            </AbsoluteCenter>
          </Box>
          <Button p={6} bg="#0F61D6" color="#fff" _hover={{
        bg: '#0F61D6',
        opacity: '0.9'
      }} onClick={() => setShowForm(2)}>
            Log in with Email Address
          </Button>
        </> : <>
          {showForm === 1 ? <PhoneInput value={userDetail} onChange={phone => setUserDetail(phone)} disableBtn={setDisableSubmitBtn} /> : <Input placeholder="Enter Email Address" p={6} value={userDetail} onChange={e => {
        setUserDetail(e.target.value);
      }} />}
          {showErrorAlert && <Alert status="error" style={{
        marginTop: '1rem'
      }}>
              <AlertIcon />
              <AlertDescription>{verifyPhoneError?.message}</AlertDescription>
              <Spacer />
              <CloseButton alignSelf="flex-start" position="relative" right="-1" top={-1} onClick={() => setShowErrorAlert(false)} />
            </Alert>}
          <Button bg="#0F61D6" color="#fff" isDisabled={!userDetail.length || showForm === 1 && isSubmitBtnDisabled || showForm === 2 && !validateEmail(userDetail).isValid} _hover={{
        bg: '#0F61D6',
        opacity: '0.9'
      }} isLoading={verifyingPhone} p={6} w="100%" onClick={() => {
        if (showForm === 2 && !validateEmail(userDetail).isValid) {
          toast({
            title: 'Invalid Email',
            description: 'Please enter a valid email address.',
            status: 'error',
            duration: 3000,
            isClosable: true
          });
          return;
        }
        verifyPhone({
          variables: {
            phone: showForm === 1 ? userDetail.substring(1) : userDetail,
            actionType: 'login'
          }
        });
      }}>
            Continue
          </Button>
          {showForm === 1 && <Button mt={2} color="blue" onClick={() => {
        setShowForm(0);
        setUserDetail('');
      }}>
              ← Back to Login Options
            </Button>}
          {showForm === 2 && <Button mt={2} color="blue" onClick={() => {
        setShowForm(0);
        setUserDetail('');
      }}>
              ← Back to Login Options
            </Button>}
        </>}
      <Text textAlign="center" data-sentry-element="Text" data-sentry-source-file="LoginForm.tsx">
        {`Don't have an account yet?`}{' '}
        <span style={{
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
      }}>
          <Link href="/register" data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">Register</Link>
        </span>
      </Text>
    </Flex>;
};
export default LoginForm;