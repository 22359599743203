import React from 'react';
import MetaHead from '../components/meta-head';
import LoginForm from '../features/authentication/forms/LoginForm';
import AuthLayout from '../features/authentication/layout/AuthLayout';
function login() {
  return <div data-sentry-component="login" data-sentry-source-file="login.tsx">
      <MetaHead title="Login to Your Account - PicknFix" description="Access your PicknFix account to manage your device insurance, repair requests, and protection plans. Secure and easy login process." data-sentry-element="MetaHead" data-sentry-source-file="login.tsx" />
      <AuthLayout heading="Hello Again!" data-sentry-element="AuthLayout" data-sentry-source-file="login.tsx">
        <LoginForm data-sentry-element="LoginForm" data-sentry-source-file="login.tsx" />
      </AuthLayout>
    </div>;
}
export async function getStaticProps() {
  return {
    props: {}
  };
}
export default login;