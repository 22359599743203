import React, { MouseEventHandler, ReactNode } from 'react';
import { Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';
type Props = {
  onClick?: MouseEventHandler<HTMLDivElement>;
  size?: string;
  icon?: ReactNode;
};
const Back = ({
  onClick,
  size = '2rem',
  icon
}: Props) => {
  const router = useRouter();
  return <div onClick={onClick ? onClick : () => router.back()} style={{
    cursor: 'pointer'
  }} data-sentry-component="Back" data-sentry-source-file="Back.tsx">
      {icon || <Image w={size} src="/images/arrow-back.svg" h="100%" alt="go-back" />}
    </div>;
};
export default Back;