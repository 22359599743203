import gql from 'graphql-tag';

export const CONFIRM_PHONE_NUMBER = gql`
  mutation confirmPhone($code: String!, $pinId: String!) {
    confirmPhone(code: $code, pinId: $pinId) {
      to
      channel
      status
      pinId
    }
  }
`;

export const SIGN_UP_USER = gql`
  mutation signupUser(
    $phone: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $servicecenter: String
    $logistics: String
    $partnerId: String
    $partnerCode: String
    $referrer: String
    $countryCode: String
  ) {
    signupUser(
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      servicecenter: $servicecenter
      logistics: $logistics
      partner_id: $partnerId
      partnerCode: $partnerCode
      referrer: $referrer
      countryCode: $countryCode
    ) {
      pinId
      token
      id
    }
  }
`;

export const VERIFY_AGREEMENT = gql`
  mutation verifyAgreement($tnc: Boolean!) {
    verifyAgreement(tnc: $tnc) {
      tnc
      phoneVerified
      userId
      token
    }
  }
`;

export const VERIFY_PHONE = gql`
  mutation verifyPhone($phone: String!, $actionType: String!) {
    verifyPhone(phone: $phone, actionType: $actionType) {
      to
      channel
      pinId
      status
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser {
    deleteUser {
      to
      channel
      status
      pinId
    }
  }
`;

export const CONFIRM_DELETE_USER = gql`
  mutation confirmDelete($code: String!, $pinId: String!) {
    confirmDelete(code: $code, pinId: $pinId) {
      message
    }
  }
`;

export const LOG_USER_IN = gql`
  mutation authenticateUser(
    $phone: String!
    $code: String!
    $pinId: String!
    $partnerCode: String
    $countryCode: String
  ) {
    authenticateUser(
      phone: $phone
      code: $code
      pinId: $pinId
      partnerCode: $partnerCode
      countryCode: $countryCode
    ) {
      id
      token
      firstname
      lastname
      tnc
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: uuid!
    $email: String
    $first_name: String
    $last_name: String
    $phone: String
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone: $phone
      }
    ) {
      email
      first_name
      last_name
      phone
    }
  }
`;

export const VALIDATE_PHONE = gql`
  mutation CheckPhoneNetwork($partner_code: String, $phone: String!) {
    checkNetwork(partner_code: $partner_code, phone: $phone) {
      isNetworkNumber
    }
  }
`;
