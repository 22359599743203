import React from 'react';
import { VALIDATE_PHONE } from '../api/mutations/auth';
import debounce from 'lodash/debounce';
import { getErrorMessage } from '../utils/util';
import { useMutation } from '@apollo/client';
const partner_code = process.env.NEXT_PUBLIC_PARTNER_CODE;
const useValidatePhone = () => {
  const [isPhoneInputFocus, setIsPhoneInputFocus] = React.useState(false);
  const [isPhoneValid, setIsPhoneValid] = React.useState<boolean | 'typing'>('typing');
  const [checkIsPhoneValid, {
    loading: isCheckingPhoneValid
  }] = useMutation(VALIDATE_PHONE, {
    fetchPolicy: 'network-only',
    onError: error => {
      getErrorMessage(error);
    }
  });
  const onPhoneInputChange = React.useCallback(async (val: string, disableBtn?: (x: boolean) => void) => {
    if (val && val.length >= 11) {
      if (partner_code === 'PNFUK') {
        setIsPhoneValid(true);
        return disableBtn?.(!true);
      }
      checkIsPhoneValid({
        variables: {
          phone: val,
          partner_code
        },
        onCompleted: data => {
          if (data.checkNetwork.isNetworkNumber === true) {
            setIsPhoneValid(true);
            return disableBtn?.(!true);
          }
          disableBtn?.(!false);
          setIsPhoneValid(false);
        }
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(debounce(onPhoneInputChange, 800),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return {
    debounceOnChange,
    onPhoneInputChange,
    isPhoneInputFocus,
    setIsPhoneInputFocus,
    isPhoneValid,
    setIsPhoneValid,
    isCheckingPhoneValid
  };
};
export default useValidatePhone;